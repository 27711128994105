import httpReauset from '@/utils/httpReauset'
const api = 'index'
// 首页
export function getIndexData (data) {
  return httpReauset({
    url: `${api}/index`,
    method: 'post',
    data: data,
  })
}
// 业务
export function getBusinessData (data) {
  return httpReauset({
    url: `${api}/business`,
    method: 'post',
    data: data,
  })
}
// 项目
export function getProjectData (data) {
  return httpReauset({
    url: `${api}/projects`,
    method: 'post',
    data: data,
  })
}

// 专家
export function getIndustryData (data) {
  return httpReauset({
    url: `${api}/expert`,
    method: 'post',
    data: data,
  })
}

// 学院
export function getCollegeData (data) {
  return httpReauset({
    url: `${api}/college`,
    method: 'post',
    data: data,
  })
}

// 招聘
export function getRecruitData (data) {
  return httpReauset({
    url: `${api}/recruit`,
    method: 'post',
    data: data,
  })
}
// 提交简历
export function getSubnotesData (data) {
  return httpReauset({
    url: `${api}/subnotes`,
    method: 'post',
    data: data,
  })
}
// 新闻
export function getNewsData (data) {
  return httpReauset({
    url: `${api}/news`,
    method: 'post',
    data: data,
  })
}
// 关于
export function getAboutData (data) {
  return httpReauset({
    url: `${api}/about`,
    method: 'post',
    data: data,
  })
}

// 联系
export function getSubmessageData (data) {
  return httpReauset({
    url: `${api}/submessage`,
    method: 'post',
    data: data,
  })
}

// 联系-分公司
export function getUnderData (data) {
  return httpReauset({
    url: `${api}/under`,
    method: 'post',
    data: data,
  })
}