import Vue from 'vue'
import VueRouter from 'vue-router'
import Hedear from '../components/Header.vue'
import { isMobile } from '../utils/utils'
import mobileHomeView from '../components/mobileCompoen/mobileHeader.vue'
let isMobileFlagRoute = isMobile() ? 'mobileHomeView' : 'homeView'
console.log(isMobileFlagRoute);

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: isMobileFlagRoute }
  },
  {
    path: '/secondLevel/HomeView',
    name: 'secondLevel',
    component: Hedear,
    children: [
      {
        path: '/secondLevel/HomeView',
        name: 'homeView',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/secondLevel/vocationalView',
        name: 'vocationalView',
        component: () => import('../views/vocationalView.vue')
      },
      {
        path: '/secondLevel/projectView',
        name: 'projectView',
        component: () => import('../views/projectView.vue')
      },
      {
        path: '/secondLevel/specialView',
        name: 'specialView',
        component: () => import('../views/specialView.vue')
      },
      {
        path: '/secondLevel/collegeView',
        name: 'collegeView',
        component: () => import('../views/collegeView.vue')
      },
      {
        path: '/secondLevel/recruitView',
        name: 'recruitView',
        component: () => import('../views/recruitView.vue')
      },
      {
        path: '/secondLevel/newsView',
        name: 'newsView',
        component: () => import('../views/newsView.vue')
      },
      {
        path: '/secondLevel/AboutView',
        name: 'AboutView',
        component: () => import('../views/AboutView.vue')
      },
      {
        path: '/secondLevel/contactUs',
        name: 'contactUs',
        component: () => import('../views/ContactUs.vue')
      },
    ]
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: mobileHomeView,
    children: [
      {
        path: '/mobile/mobileHome',
        name: 'mobileHomeView',
        component: () => import('../views/mobile/mobileHome.vue'),
      },
      {
        path: '/mobile/mobileVocational',
        name: 'mobileVocational',
        component: () => import('../views/mobile/mobileVocational.vue')
      },
      {
        path: '/mobile/mobileProject',
        name: 'mobileProject',
        component: () => import('../views/mobile/mobileProject.vue')
      },
      {
        path: '/mobile/mobileSpecial',
        name: 'mobileSpecialView',
        component: () => import('../views/mobile/mobileSpecial.vue')
      },
      {
        path: '/mobile/mobileCollege',
        name: 'mobileCollege',
        component: () => import('../views/mobile/mobileCollege.vue')
      },
      {
        path: '/mobile/mobileRecruit',
        name: 'mobileRecruit',
        component: () => import('../views/mobile/mobileRecruit.vue')
      },
      {
        path: '/mobile/mobileNews',
        name: 'mobileNews',
        component: () => import('../views/mobile/mobileNews.vue')
      },
      {
        path: '/mobile/mobileAbout',
        name: 'mobileAbout',
        component: () => import('../views/mobile/mobileAbout.vue')
      },
      {
        path: '/mobile/mobileContact',
        name: 'mobileContact',
        component: () => import('../views/mobile/mobileContact.vue')
      },
    ]
  }
]

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savePosition) {
    if (savePosition) {
      return savePosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router
