<template>
  <div class="mheaderBox">
    <div
      class="mHead"
      :style="{ background: background, backdropFilter: backdropFilter }"
      :class="navShow ? 'navOn' : 'navOff'"
    >
      <div
        class="mHeadLeft"
        :style="'background-image: url(' + logoImg + ')'"
        @click="logoLink"
        v-show="logoFalse"
      ></div>
      <div
        class="mHeadLeft"
        :style="'background-image: url(' + logoImgFalse + ')'"
        @click="logoLink"
        v-show="!logoFalse"
      ></div>
      <el-button @click="drawer = true" type="primary" label="ltr">
        <i class="iconfont" :style="{ color: color }">&#xe611;</i>
      </el-button>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      :show-close="true"
      :with-header="false"
      :size="'100%'"
    >
      <div class="mHead">
        <div
          class="mHeadLeft"
          :style="'background-image: url(' + logoImg + ')'"
        ></div>
        <el-button @click="handleClose" type="primary" label="ltr">
          <i class="iconfont" style="font-size: 0.5rem; opacity: 0.7"
            >&#xe616;</i
          >
        </el-button>
      </div>
      <div class="content-nav">
        <div
          :class="{ active: $route.path === item.path }"
          class="nav-item"
          v-for="(item, index) in tabHdear"
          :key="index"
          @click="handleMouseEnter(item, index)"
        >
          <span>{{ item.name }}</span>
          <div class="nav-line" :style="{ background: backgroundLine }"></div>
          <div
            class="vocationalShow"
            v-show="vocationFalse === index && index == 1"
          >
            <div
              class="vocationalTab"
              v-for="it in vocatabList"
              :key="it.id"
              @click="vocatabClick(it, item)"
            >
              {{ it.title }}
            </div>
          </div>
          <div
            class="collegeShow"
            v-show="vocationFalse === index && index == 4"
          >
            <div
              class="collegeTab"
              v-for="(it, index) in collegeTabList"
              :key="index"
              @click="collegeTabClick(it, item)"
            >
              {{ it.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="right-search" @click="changeLang(getLangData)">
        {{ getLangData == "CN" ? "EN" : "CN" }}
      </div>
    </el-drawer>
    <router-view :nameScero="nameScero" :idData="idData" />
    <mobileindex
      @getScroll="getScroll"
      @getScrollId="getScrollId"
    ></mobileindex>
  </div>
</template>

<script>
import { getIndexData } from "@/api";
import mobileindex from "./mobileindex.vue";
export default {
  components: {
    mobileindex,
  },
  data() {
    return {
      getLangData: localStorage.getItem("lang") || "CN",
      drawer: false,
      direction: "ltr",
      drawerFalse: false,
      routerIndex: 0,
      tabHdear: [
        { name: this.$t("header.home"), path: "/mobile/mobileHome" },
        { name: this.$t("header.yewu"), path: "/mobile/mobileVocational" },
        { name: this.$t("header.xiangmu"), path: "/mobile/mobileProject" },
        { name: this.$t("header.zhuanjia"), path: "/mobile/mobileSpecial" },
        { name: this.$t("header.xueyuan"), path: "/mobile/mobileCollege" },
        { name: this.$t("header.zhaopin"), path: "/mobile/mobileRecruit" },
        { name: this.$t("header.xinwen"), path: "/mobile/mobileNews" },
        { name: this.$t("header.guanyu"), path: "/mobile/mobileAbout" },
        { name: this.$t("header.lianxi"), path: "/mobile/mobileContact" },
      ],
      logoImg: require("../../assets/mobile/mobileLogo.png"),
      vocationFalse: null,
      background: "rgba(0,0,0,0)",
      logoImgFalse: require("../../assets/home/footLogo.png"),
      logoFalse: true,
      topUp: "",
      navShow: true,
      backgroundLine: "#fff",
      vocatabList: [
        { name: "游戏完整开发", idName: "kaifa" },
        { name: "2D游戏美术", idName: "youxi" },
        { name: "3D游戏美术", idName: "meishu" },
        { name: "游戏特效", idName: "texiao" },
        { name: "游戏动作", idName: "dongzuo" },
      ],
      collegeTabList: [
        { name: this.$t("header.yxt"), idName: "youxiao", id: 1 },
        { name: this.$t("header.yxd"), idName: "youxiao", id: 2 },
        { name: this.$t("header.djm"), idName: "youxiao", id: 3 },
        { name: this.$t("header.yx"), idName: "youxiao", id: 4 },
      ],
      nameScero: 0,
      backdropFilter: "saturate(180%) blur(0)",
      color: "#fff",
      idData: 0,
    };
  },
  mounted() {
    this.getIndexDataList();
    window.addEventListener("scroll", () => {
      this.topUp =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
  },
  watch: {
    topUp(newValue, oldValue) {
      // 等新值大于100的时候再做变化（优化一下）
      if (newValue > 100) {
        if (newValue > oldValue) {
          this.navShow = false;
          console.log("向下滚动");
        } else {
          this.navShow = true;
          this.color = "#333";
          this.backdropFilter = "saturate(180%) blur(20px)";
          this.logoFalse = false;
        }
      }
      if (newValue == 0) {
        if (newValue < oldValue) {
          this.navShow = true;
          this.logoFalse = true;
          this.color = "#fff";
          this.backdropFilter = "saturate(180%) blur(0)";
          console.log("向上滚动");
        }
      }
    },
  },
  methods: {
    changeLang(lang) {
      let langTetx = lang === "CN" ? "EN" : "CN";
      localStorage.setItem("lang", langTetx);
      this.$i18n.locale = langTetx;
      window.location.reload();
    },
    getIndexDataList() {
      getIndexData({}).then((res) => {
        this.vocatabList = res.data.business;
        this.collegeTabList = res.data.college.map((item) => {
          return {
            ...item,
            idName: `${"youxiao"}`,
          };
        });
      });
    },
    handleClose() {
      this.drawer = false;
    },
    changeRoute(item, index) {
      if (index == 1 || index == 4) {
        return;
      }
      this.$router.push({
        path: item.path,
      });
      this.drawer = false;
    },
    logoLink() {
      this.$router.push({
        path: "/mobile/mobileHome",
      });
    },
    handleMouseEnter(item, index) {
      this.vocationFalse = index;
      if (index == 1 || index == 4) {
        return;
      }
      this.$router.push({
        path: item.path,
      });
      this.drawer = false;
    },
    getScroll(it,item) {
      this.vocatabClick(it, item)
    },  
    vocatabClick(it, item) {
      if (item.path === this.$route.path) {
        this.nameScero = it.id;
      } else {
        this.$router.push({
          path: item.path,
          query: {
            id: it.id,
          },
        });
      }
      this.drawer = false;
    },
    getScrollId(it,item) {
      this.collegeTabClick(it, item)
    },  
    collegeTabClick(it, item) {
      if (item.path === this.$route.path) {
        this.idData = it.id;
      } else {
        this.$router.push({
          path: item.path,
          query: {
            id: it.id,
          },
        });
      }
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.navOn {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out 0.2s;
  transform: translateZ(0);
}

.navOff {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out 0.2s;
  transform: translate3d(0, -100%, 0);
}

.mheaderBox {
  .mHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 0.5rem;
    width: 100%;
    box-sizing: border-box;
    z-index: 999;
    .mHeadLeft {
      width: 2.16rem;
      height: 0.47rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    ::v-deep .el-button {
      width: 0.6rem;
      height: 0.6rem;
      background: none;
      border: 0;
      padding: 0;
      i {
        font-size: 0.5rem;
        margin-top: -0.18rem;
        display: block;
      }
      img {
        width: 0.6rem;
        height: 0.6rem;
      }
    }
  }

  ::v-deep .el-drawer__body {
    background: #000;
    // margin-bottom: 0.5rem;
  }
}

.content-nav {
  .nav-item {
    font-weight: 700;
    font-size: 0.4rem;
    color: #fff;
    cursor: pointer;
    text-align: center;
    margin-top: 0.8rem;
  }
}

.right-search {
  color: #fff;
  font-size: 0.4rem;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
  margin-top: 0.8rem;

  ::v-deep .el-dropdown {
    color: #fff;
  }
}

.vocationalShow,
.collegeShow {
  width: 100%;
  transition: all 0.3s;

  .vocationalTab,
  .collegeTab {
    color: #fff;
    font-size: 0.32rem;
    font-weight: 400;
    opacity: 0.9;
    margin-top: 0.55rem;
  }
}
</style>