<template>
  <div>
    <div
      class="jFoot"
      :style="{ marginTop: marginTop, background: background }"
    >
      <div
        class="skew"
        :style="{ backgroundColor: backgroundColor, display: display }"
      ></div>
      <div class="jfootPos">
        <div class="famousFoot">
          <div
            class="famous_left"
            :style="'background-image: url(' + footLogo + ')'"
          ></div>
          <div class="famous_right">
            <div
              :class="{ active: $route.path === item.path }"
              class="nav-item"
              v-for="(item, index) in tabHdear"
              :key="index"
              @click="handleMouseEnter(item, index)"
            >
              <span>{{ item.name }}</span>
              <div
                class="vocationalShow"
                v-show="vocationFalse === index && index == 1"
              >
                <div
                  class="vocationalTab"
                  v-for="it in vocatabList"
                  :key="it.id"
                  @click="vocatabClick(it, item)"
                >
                  {{ it.title }}
                </div>
              </div>
              <div
                class="collegeShow"
                v-show="vocationFalse === index && index == 4"
              >
                <div
                  class="collegeTab"
                  v-for="(it, index) in collegeTabList"
                  :key="index"
                  @click="collegeTabClick(it, item)"
                >
                  {{ it.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyFoot">
          <div class="copy_left">
            <div class="copyImg">
              <img src="../../assets/home/xunlei.png" alt="" />
            </div>
            <div class="copyImg">
              <img src="../../assets/home/wechat.png" alt="" />
              <div class="copyHove">
                <img src="../../assets/home/erweima.png" alt="" />
              </div>
            </div>
            <div class="copyImg">
              <img src="../../assets/home/weibo.png" alt="" />
            </div>
            <div class="copyImg">
              <img src="../../assets/home/facebook.png" alt="" />
            </div>
          </div>
          <div class="copy_right">
            <span>{{ copyText }}</span>
            <span>Web Design UG</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
  <script>
  import { getIndexData } from "@/api";
import mobileHeader from "../mobileCompoen/mobileHeader.vue";
export default {
  components: {
    mobileHeader,
  },
  data() {
    return {
      tabHdear: [
        { name: this.$t("header.home"), path: "/mobile/mobileHome" },
        { name: this.$t("header.yewu"), path: "/mobile/mobileVocational" },
        { name: this.$t("header.xiangmu"), path: "/mobile/mobileProject" },
        { name: this.$t("header.zhuanjia"), path: "/mobile/mobileSpecial" },
        { name: this.$t("header.xueyuan"), path: "/mobile/mobileCollege" },
        { name: this.$t("header.zhaopin"), path: "/mobile/mobileRecruit" },
        { name: this.$t("header.xinwen"), path: "/mobile/mobileNews" },
        { name: this.$t("header.guanyu"), path: "/mobile/mobileAbout" },
        { name: this.$t("header.lianxi"), path: "/mobile/mobileContact" },
      ],
      footLogo: require("../../assets/home/footLogo.png"),
      copyText: "© 2023 Kaedim Inc. All rights reserved",
      backgroundColor: "#fff",
      marginTop: "0",
      background: "",
      display: "",

      vocationFalse: null,
      nameScero: 0,
      vocatabList: [
        { name: "游戏完整开发", idName: "kaifa" },
        { name: "2D游戏美术", idName: "youxi" },
        { name: "3D游戏美术", idName: "meishu" },
        { name: "游戏特效", idName: "texiao" },
        { name: "游戏动作", idName: "dongzuo" },
      ],
      collegeTabList: [
        { name: this.$t("header.yxt"), idName: "youxiao", id: 1 },
        { name: this.$t("header.yxd"), idName: "youxiao", id: 2 },
        { name: this.$t("header.djm"), idName: "youxiao", id: 3 },
        { name: this.$t("header.yx"), idName: "youxiao", id: 4 },
      ],
    };
  },
  mounted() {
    // console.log(this.$route.path);
    this.getIndexDataList();
    let url = window.location.href.split("mobile/")[1];
    console.log(window.location.href.split("mobile/")[1]);
    if (
      url === "mobileHome" ||
      url === "mobileAbout" ||
      url === "mobileVocational"
    ) {
      this.backgroundColor = "#f5f5f5";
      this.marginTop = "1.08rem";
    } else {
      this.backgroundColor = "#fff";
      this.marginTop = "0";
      this.background = "transparent";
    }
    if (this.$route.path === "/mobile/mobileVocational") {
      this.backgroundColor = "#f5f5f5";
      this.marginTop = "1.08rem";
      this.background = "#f5f5f5";
    }
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        path: item.path,
      });
    },
    getIndexDataList() {
      getIndexData({}).then((res) => {
        this.vocatabList = res.data.business;
        this.collegeTabList = res.data.college.map((item) => {
          return {
            ...item,
            idName: `${"youxiao"}`,
          };
        });
      });
    },
    handleMouseEnter(item, index) {
      this.vocationFalse = index;
      if (index == 1 || index == 4) {
        return;
      }
      this.$router.push({
        path: item.path,
      });
      this.drawer = false;
    },
    vocatabClick(it, item) {
      if (item.path === this.$route.path) {
        this.nameScero = it.id;
        this.$emit('getScroll', it,item)
      } else {
        this.$router.push({
          path: item.path,
          query: {
            id: it.id,
          },
        });
      }
    },
    collegeTabClick(it, item) {
      if (item.path === this.$route.path) {
        this.idData = it.id;
        this.$emit('getScrollId', it,item)
      } else {
        this.$router.push({
          path: item.path,
          query: {
            id: it.id,
          },
        });
      }
    },
  },
};
</script>
      
  <style scoped lang="scss">
.jFoot {
  position: relative;
  background: #f5f5f5;
  margin-top: 1.08rem;
  .skew {
    overflow: hidden;
    background-color: #f5f5f5;
    transform: skew(0, -2.4deg);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform-origin: left top;
    // z-index: -1;
  }
}
.jfootPos {
  position: relative;
  padding: 1rem 0.75rem;
  width: 100%;
  box-sizing: border-box;
}
.famousFoot {
  max-width: 100%;
  margin: 0 auto;
  align-items: center;
  .famous_left {
    width: 2.7rem;
    height: 0.59rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .famous_right {
    margin-top: 1rem;
  }
  .nav-item {
    color: #333;
    font-size: 0.28rem;
    font-weight: 400;
    cursor: pointer;
    border-top: 0.01rem solid rgba(153, 153, 153, 0.5);
    padding: 0.3rem 0 0.3rem 0.2rem;
    transition: all 0.3s;
  }
  .nav-item:last-child {
    border-bottom: 0.01rem solid rgba(153, 153, 153, 0.5);
  }
  // .nav-item:hover {
  //   background: #333;
  //   color: #fff;
  // }
}
.copyFoot {
  max-width: 100%;
  margin: 0 auto;
  .copy_left {
    display: flex;
    gap: 0.4rem;
    margin-top: 1rem;
  }
  .copyImg {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .copyImg:nth-child(2):hover .copyHove {
    display: block;
  }
  .copyHove {
    position: absolute;
    top: 0.48rem;
    left: -0.3rem;
    width: 1.2rem;
    height: 1.2rem;
    display: none;
  }
  .copy_right {
    margin-top: 1rem;
    color: rgba(51, 51, 51, 0.75);
    font-size: 0.16rem;
    font-weight: 400;
    text-transform: capitalize;
    span:last-child {
      margin-left: 0.2rem;
    }
  }
}
.vocationalShow,
.collegeShow {
  width: 100%;
  transition: all 0.3s;

  .vocationalTab,
  .collegeTab {
    color: #333;
    font-size: 0.26rem;
    font-weight: 400;
    opacity: 0.9;
    margin-top: 0.55rem;
    border-bottom: 0.01rem solid;
    width: max-content;
  }
}
</style>