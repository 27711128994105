import axios from 'axios'
import { Notification } from 'element-ui'
import config from './config'
let getLangDataText = localStorage.getItem('lang')
let lang = getLangDataText ? getLangDataText.toUpperCase() : 'CN';
axios.defaults.baseURL = config.baseURL
function getErrorMsg(msg) {
    Notification.error({
        title: '错误',
        message: msg,
    });
}
axios.interceptors.request.use(
    config => {
        // console.log('config', config);
        // config.headers.Authorization = token // 请求头添加token
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)
// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        console.log(error);
        if (error.response.status) {
            switch (error.response.status) {
                case 500:
                    getErrorMsg('系统异常！')
                    break;
                default:
                    getErrorMsg('系统异常！')
            }
            return Promise.reject(error.response)
        }
    }
)
function setRequest({ url, method, data, params }) {
    return new Promise((resolve, reject) => {
        axios.request({
            url: url,
            method: method,
            headers: {},
            data: data ? { ...data, language: lang } : data,
            params: params ? { ...params, language: lang } : params,
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            console.dir(err);
            getErrorMsg(err.statusText || '系统异常,请检查网络！')
            // Message.error('错误' + err)
            reject(err)
        })
    })
}
export default setRequest;