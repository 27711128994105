<template>
  <div>
    <div class="header-box"
         :style="{ background: background }"
         :class="navShow ? 'navOn' : 'navOff'">
      <div class="left-logo"
           :style="'background-image: url(' + logoImg + ')'"
           @click="logoLink"
           v-show="logoFalse"></div>
      <div class="left-logo"
           :style="'background-image: url(' + logoImgFalse + ')'"
           @click="logoLink"
           v-show="!logoFalse"></div>
      <!-- 中文样式间距 -->
      <div class="content-nav"
           v-if="getLangData === 'CN'">
        <div :class="{ active: $route.path === item.path }"
             class="nav-item"
             v-for="(item, index) in tabHdear"
             :key="index"
             @mouseenter="handleMouseEnter(index)"
             @mouseleave="handleMouseLeaveone(index)">
          <span :style="{ color: color }"
                @click="changeRoute(item)">{{
            item.name
          }}</span>
          <div class="nav-line"
               :style="{ background: backgroundLine }"></div>
          <div class="vocationalShow"
               @mouseleave="handleMouseLeave(index)"
               v-show="vocationFalse === index && index == 1">
            <div class="vocationalTab"
                 v-for="(it, index) in vocatabList"
                 :key="index"
                 @click="vocatabClick(it, item)">
              {{ it.title }}
              <div class="vocaline"
                   :style="{ background: backgroundLine }"></div>
            </div>
          </div>
          <div class="collegeShow"
               @mouseleave="handleMouseLeave(index)"
               v-show="vocationFalse === index && index == 4">
            <div class="collegeTab"
                 v-for="(it, index) in collegeList"
                 :key="index"
                 @click="collegeTabClick(it, item)">
              {{ it.title }}
              <div class="vocaline"
                   :style="{ background: backgroundLine }"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 英文样式间距 -->
      <div class="content-nav content-ennaav"
           v-if="getLangData === 'EN'">
        <div :class="{ active: $route.path === item.path }"
             class="nav-item"
             v-for="(item, index) in tabHdear"
             :key="index"
             @mouseenter="handleMouseEnter(index)"
             @mouseleave="handleMouseLeaveone(index)">
          <span :style="{ color: color }"
                @click="changeRoute(item)">{{
            item.name
          }}</span>
          <div class="nav-line"
               :style="{ background: backgroundLine }"></div>
          <!-- 业务 -->
          <div class="vocationalShow"
               @mouseleave="handleMouseLeave(index)"
               v-show="vocationFalse === index && index == 1">
            <div class="vocationalTab"
                 v-for="(it, index) in vocatabList"
                 :key="index"
                 @click="vocatabClick(it, item)">
              {{ it.title }}
              <div class="vocaline"
                   :style="{ background: backgroundLine }"></div>
            </div>
          </div>
          <!-- 学院 -->
          <div class="collegeShow"
               @mouseleave="handleMouseLeave(index)"
               v-show="vocationFalse === index && index == 4">
            <div class="collegeTab"
                 v-for="(it, index) in collegeList"
                 :key="index"
                 @click="collegeTabClick(it, item)">
              {{ it.title }}
              <div class="vocaline"
                   :style="{ background: backgroundLine }"></div>
            </div>
          </div>
        </div>
      </div>
      <div @click="changeLang(getLangData)"
           class="right-search"
           :style="{ color: color, border: border }">
        {{ getLangData == "CN" ? "EN" : "CN" }}
      </div>
    </div>
    <router-view :nameScero="nameScero"
                 :idData="idData" />
  </div>
</template>
<script>
import { getIndexData } from "@/api";
export default {
  data () {
    return {
      getLangData: localStorage.getItem("lang") || "CN",
      routerIndex: 0,
      tabHdear: [
        { name: this.$t("header.home"), path: "/secondLevel/HomeView", id: 0 },
        { name: this.$t("header.yewu"), path: "/secondLevel/vocationalView" },
        { name: this.$t("header.xiangmu"), path: "/secondLevel/projectView" },
        { name: this.$t("header.zhuanjia"), path: "/secondLevel/specialView" },
        { name: this.$t("header.xueyuan"), path: "/secondLevel/collegeView" },
        { name: this.$t("header.zhaopin"), path: "/secondLevel/recruitView" },
        { name: this.$t("header.xinwen"), path: "/secondLevel/newsView" },
        { name: this.$t("header.guanyu"), path: "/secondLevel/AboutView" },
        { name: this.$t("header.lianxi"), path: "/secondLevel/contactUs" },
      ],
      logoImg: require("../assets/home/headerLogo.png"),
      vocationFalse: null,
      background: "rgba(0,0,0,0)",
      color: "#fff",
      border: "1px solid rgba(255, 255, 255, 0.5)",
      logoImgFalse: require("../assets/home/footLogo.png"),
      logoFalse: true,
      topUp: "",
      navShow: true,
      backgroundLine: "#fff",
      colorNav: "#333",
      vocatabList: [
        { name: "游戏完整开发", idName: "kaifa" },
        { name: "2D游戏美术", idName: "youxi" },
        { name: "3D游戏美术", idName: "meishu" },
        { name: "游戏特效", idName: "texiao" },
        { name: "游戏动作", idName: "dongzuo" },
      ],
      collegeList: [
        { name: this.$t("header.yxt"), idName: "youxiao", id: 1 },
        { name: this.$t("header.yxd"), idName: "youxiao", id: 2 },
        { name: this.$t("header.djm"), idName: "youxiao", id: 3 },
        { name: this.$t("header.yx"), idName: "youxiao", id: 4 },
      ],
      nameScero: "",
      idData: 0,
      // backdropFilter: "saturate(180%) blur(0)",
    };
  },
  computed: {},
  created () {
    this.getIndexDataList();
  },
  mounted () {
    window.addEventListener("scroll", () => {
      this.topUp =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
    });
    // let url = window.location.href.split("secondLevel/")[1];
    // console.log(window.location.href.split("secondLevel/")[1], 'qwqwwqwwqww');
    // let hhhhh = document.querySelectorAll('.nav-item')
    // console.log(hhhhh, 'jhhhhhhhhhhhh');

    // if (
    //   this.tabHdear.name == this.$t("header.home")
    // ) {
    //   const parentLine = document.querySelector(
    //     ".nav-item:nth-child(1) .nav-line"
    //   );
    //   console.log(parentLine, '0000000000000');
    //   parentLine.style.width = "50%"
    // } else {
    //   const parentLine = document.querySelector(
    //     ".nav-item:nth-child(1) .nav-line"
    //   );
    //   console.log(parentLine, 'sgdajksdgasjkdgajks');
    //   parentLine.style.width = "0"
    // }
  },
  watch: {
    topUp (newValue, oldValue) {
      // 等新值大于100的时候再做变化（优化一下）
      if (newValue > 100) {
        if (newValue > oldValue) {
          this.navShow = false;
          console.log("向下滚动");
        } else {
          this.navShow = true;
          this.color = "#333";
          this.border = "1px solid rgba(153, 153, 153, 0.50)";
          this.backgroundLine = "#333";
          this.background = "#fff";
          // this.backdropFilter = "saturate(180%) blur(20px)";
          this.hover = "#333";
          this.logoFalse = false;
        }
      }
      if (newValue == 0) {
        if (newValue < oldValue) {
          this.logoFalse = true;
          this.background = "rgba(0,0,0,0)";
          this.navShow = true;
          this.color = "#fff";
          this.border = "1px solid rgba(255, 255, 255, 0.5)";
          this.backgroundLine = "#fff";
          this.hover = "#333";
          console.log("向上滚动");
        }
      }
    },
  },
  methods: {
    changeLang (lang) {
      let langTetx = lang === "CN" ? "EN" : "CN";
      localStorage.setItem("lang", langTetx);
      this.$i18n.locale = langTetx;
      window.location.reload();
    },
    // 接受子组件数据
    getIndexDataList () {
      getIndexData({}).then((res) => {
        console.log(res);

        this.vocatabList = res.data.business.map((item) => {
          return {
            ...item,
            idName: `idName${item.id}`,
          };
        });
        this.collegeList = res.data.college.map((item) => {
          return {
            ...item,
            idName: `${"youxiao"}`,
          };
        });
      });
    },
    changeRoute (item) {
      this.$router.push({
        path: item.path,
      });
    },
    logoLink () {
      this.$router.push({
        path: "/secondLevel/HomeView",
      });
    },
    handleMouseEnter (index) {
      this.vocationFalse = index;
      console.log(index);
      this.background = "#fff";
      this.color = "#333";
      this.backgroundLine = "#333";
      this.border = "1px solid rgba(153, 153, 153, 0.50) ";
      this.logoFalse = false;
      if (this.topUp == 0) {
        if (index == 1 || index == 4) {
          this.background = "#fff";
          this.color = "#333";
          this.backgroundLine = "#333";
          this.border = "1px solid rgba(153, 153, 153, 0.50) ";
          this.logoFalse = false;
        } else {
          this.background = "rgba(0,0,0,0)";
          this.color = "#fff";
          this.backgroundLine = "#fff";
          this.border = "1px solid rgba(255, 255, 255, 0.5)";
          this.logoFalse = true;
        }
      }
    },
    handleMouseLeave () {
      this.vocationFalse = false;
      this.background = "rgba(0,0,0,0)";
      this.color = "#fff";
      this.backgroundLine = "#fff";
      this.border = "1px solid rgba(255, 255, 255, 0.5)";
      this.logoFalse = true;
    },
    handleMouseLeaveone () {
      this.background = "#fff";
      this.color = "#333";
      this.backgroundLine = "#333";
      this.border = "1px solid rgba(153, 153, 153, 0.50) ";
      this.logoFalse = false;
      if (this.topUp == 0) {
        this.background = "rgba(0,0,0,0)";
        this.color = "#fff";
        this.backgroundLine = "#fff";
        this.border = "1px solid rgba(255, 255, 255, 0.5)";
        this.logoFalse = true;
      }
    },
    vocatabClick (it, item) {
      if (item.path === this.$route.path) {
        this.nameScero = it.idName;
      } else {
        this.$router.push({
          path: item.path,
        });
        window.sessionStorage.setItem("anchorPoint", it.idName);
      }
    },
    collegeTabClick (it, item) {
      if (item.path === this.$route.path) {
        this.idData = it.id;
      } else {
        this.$router.push({
          path: item.path,
          query: {
            id: it.id,
          },
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.navOn {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out 0.2s;
  transform: translateZ(0);
  background: #fff;
}

.navOff {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in-out 0.2s;
  transform: translate3d(0, -100%, 0);
}

.header-box {
  width: 100%;
  height: 100px;
  padding: 0 100px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  // background:rgba(0, 0, 0, 0);
  z-index: 10000;
  transition: all 0.3s;

  .left-logo {
    width: 144px;
    height: 40px;
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content-nav {
    display: flex;
    gap: 100px;
    .active {
      .nav-line {
        width: 50%;
        height: 2px;
        background: #fff;
        margin: 10px auto 0;
        transition: all 0.5s ease-in-out;
      }
    }
    .nav-item {
      font-weight: 700;
      font-size: 20px;
      color: #fff;
      cursor: pointer;
    }

    .nav-line {
      width: 0;
      height: 2px;
      background: #fff;
      margin: 10px auto 0;
      transition: all 0.5s ease-in-out;
    }

    .nav-item:hover .nav-line {
      width: 50%;
      margin: 10px auto 0;
    }
  }
  .content-ennaav {
    gap: 67px;
    .vocationalShow {
      padding: 28px 0 32px 542px;
    }
    .collegeShow {
      padding: 28px 0 32px 970px;
    }
    .nav-item {
      font-size: clamp(16px, 1.1805555556vw, 1.1805555556vw);
    }
  }

  .right-search {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 5px 14px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    margin-top: -9px;

    ::v-deep .el-dropdown {
      color: #fff;
    }

    .el-dropdown-link {
      padding: 5px 14px;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
}

.vocationalShow,
.collegeShow {
  position: absolute;
  left: 0;
  top: 100px;
  background: rgba(255, 255, 255, 0.75);
  width: 100%;
  padding: 28px 0 32px 559px;
  box-sizing: border-box;
  display: flex;
  gap: 60px;
  transition: all 0.3s;

  .vocationalTab,
  .collegeTab {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    .vocaline {
      width: 0;
      height: 2px;
      margin: 10px auto 0;
      background: #fff;
      transition: all 0.5s ease-in-out;
    }
  }
  .vocationalTab:hover,
  .collegeTab:hover {
    .vocaline {
      width: 50%;
      margin: 10px auto 0;
    }
  }
}

.collegeShow {
  padding: 28px 0 32px 979px;
}
</style>