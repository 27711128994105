import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale';
import messages from './langs'
Vue.use(VueI18n)
//从localStorage获取语言选择。
const i18n = new VueI18n({
    // locale: localStorage.lang || 'EN', //初始未选择默认 cn 中文
    locale: localStorage.getItem('lang') || 'CN', //初始未选择默认 cn 中文
    messages,
    silentFallbackWarn: true // 静默警告
})
locale.i18n((key, value) => i18n.t(key, value)) //兼容element
export default i18n
